.main-page-container {
  width: 100%;
  height: 100%;
}

.map-sidepanel {
  min-height: 100%;
}

.controls-sidepanel-parent {
  overflow: auto;
  height: 100%;
  padding: 0px 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sales-demo-query-list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  >.query-list-element {
    padding-bottom: 16px;
  }
}